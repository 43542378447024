import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Renewals demo" />
        <Nav activeTab="blog" />
        <div>
          <div>

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Renewals demo</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Renewals demo</h1>
              </div>

              <div className="case-study" style={{ paddingTop: 1 }}>

                <div className="article">
                  <div className='article-intro article-inner'>

                    <p style={{ margin: 0 }}>Watch the video below to see how ShuttleID can down your school bus pass renewals from days and weeks to just seconds and minutes!</p>
                    <div style={{ padding: '64.63% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/728815440?h=7351e2d7dc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullscreen style={{ position: 'absolute', top:0, left:0, width: '100%', height: '100%' }} title="ShuttleID - Renewals demo"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                    <p>In this short clip Chris Bell, ShuttleID director, demonstrates:</p>
                    <ul>
                      <li>[00:12] Why renewals are responsible for up to 80% of your admin</li>
                      <li>[00:31] How you can renew dozens of school bus passes with one click</li>
                      <li>[01:25] How parents can renew their school bus pass in seconds</li>
                    </ul>
                    {/* <p>.</p> */}
                    <p><Link className="link" to="/contact/">Contact us</Link> for more information and to arrange a full demo.</p>
                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage